//获取链接中的参数
export const getUrlParam = (variable: string) => {
    // 因为google shopping会传两套参数，要取后面一套
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    var result: any[] = [];
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            result.push(pair[1]);
        }
    }
    if (result.length > 0) {
        return result[result.length - 1];
    } else {
        return "";
    }
};
