import Cookies from "js-cookie";
import { getUrlParam } from "./url";
// Todo 上正式需要开启校验
// const isProd = process.env.NODE_ENV === "production";
const isProd = true;

const save = (inviteCode: string): void => {
    const temp = window.location.host.split(".").reverse();
    const root_domain = "." + temp[1] + "." + temp[0];
    if (isProd && inviteCode)
        Cookies.set("_inviteCode", inviteCode, {
            // expires: 3,
            expires: 30,
            domain: root_domain,
        });
};
const remove = () => {
    const temp = window.location.host.split(".").reverse();
    const root_domain = "." + temp[1] + "." + temp[0];
    if (isProd)
        Cookies.remove("_inviteCode", { domain: root_domain, path: "/" });
};

const getKey = () => {
    return isProd ? Cookies.get("_inviteCode") : undefined;
};

const searchKey = () => {
    return getUrlParam("inviteCode");
};
//保存inviteCode 埋点收集注册事件
export default {
    save,
    remove,
    getKey,
    searchKey,
};
