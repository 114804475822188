import Cookies from "js-cookie";
import { getUrlParam } from "./url";

const isProd = process.env.publicMode === "production";

const save = (channelId: string): void => {
    const temp = window.location.host.split(".").reverse();
    const root_domain = "." + temp[1] + "." + temp[0];
    if (isProd && channelId)
        Cookies.set("_channel_id", channelId, {
            domain: root_domain,
        });
};
const remove = () => {
    const temp = window.location.host.split(".").reverse();
    const root_domain = "." + temp[1] + "." + temp[0];
    if (isProd)
        Cookies.remove("_channel_id", { domain: root_domain, path: "/" });
};

const getKey = () => {
    return isProd ? Cookies.get("_channel_id") : undefined;
};

const searchKey = () => {
    return getUrlParam("channel_id");
};
//保存channelid 埋点收集注册事件
export default {
    save,
    remove,
    getKey,
    searchKey,
};
