// import "antd/dist/antd.css";
// require("../styles/antd.less");
import "@/styles/tailwind.css";
import "@/styles/animate.css";
import "@/styles/custom.css";
import "@/styles/search.css";
// import "../styles/odometer-theme-default.css";

import type { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import * as gtag from "../utils/gtag";
import Head from "next/head";
import Channel from "@/utils/channel";
import InviteCode from "@/utils/inviteCode";
import buriedsdk from "../utils/zz-buried/index";
import { getCookie, setCookie } from "cookies-next";
import { getDomain } from "@/utils/auth";
import "@/styles/globals.css";

const isProd = process.env.publicMode === "production";
// import buriedsdk from "../utils/zz-buried/index"
interface InfoType {
    locale: string;
}

function MyApp({ Component, pageProps }: AppProps) {
    const router = useRouter();
    const { locales, locale: activeLocale } = router;
    const openPageView = false; //是否不关闭自动上报pv
    useEffect(() => {
        let token = getCookie("authorized-token") as string;
        let data = (token ? JSON.parse(token) : token) as any;
        const temp = window.location.host.split(".").reverse();
        const root_domain = "." + temp[1] + "." + temp[0];

     
        if (process.browser) {

            buriedsdk.init(
                14,
                process.env.envType === "production" ? "prod" : "test",
                token ? data.accessToken : undefined,
                openPageView
            );
            buriedsdk.buried({
                event: "pageView",
                URL: process.env.publicApiDomain,
            });
          Channel.save(Channel.searchKey());
          InviteCode.save(InviteCode.searchKey());
            setCookie("CURRENT_URL", window?.location?.href);
            if (localStorage?.getItem("BURIEDSDK_DEVICEID")) {
                // setCookie(
                //     "BURIEDSDK_DEVICEID",
                //     localStorage?.getItem("BURIEDSDK_DEVICEID")
                // );
            }
            if (getCookie("BURIEDSDK_DEVICEID")) {
                localStorage?.setItem(
                    "BURIEDSDK_DEVICEID",
                    getCookie("BURIEDSDK_DEVICEID") as string
                );
            }
        }
        const handleRouteChange = (url: URL, { shallow }: { shallow: any }) => {
      
            //保存channelid 埋点收集注册事件
            if (isProd) {
                gtag.pageview(url);
            }
       
        };

        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            return router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, []);

    useEffect(() => {
        const handleClick = (e: any) => {
            setCookie("current-page", window.location.href);
            // console.log("~~~~~~~~~~", e);
        };
        window.addEventListener("click", handleClick);
        return () => {};
    }, []);
    const hreflang = {
        'vi': 'vn',
        'id': 'id'
    }
    // console.log('router---------', router)
    return (
        <>
            <Head>
             
    <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>

                <link rel="alternate" hrefLang={'en'} href={`${process.env.publicUrl}/${'en'}${router.asPath}`} />
                <link rel="alternate" hrefLang="x-default" href={`${process.env.publicUrl}/${'en'}${router.asPath}`} />
                {router.locales?.map(item =>  item !='en' && !router.pathname.includes('/blogs') && <link rel="alternate" hrefLang={hreflang[item] ? `${item}-${hreflang[item]}`: item}
                    href={`${process.env.publicUrl}/${item}${router.asPath}`}/>)}
             

                 <script src="/js/bounty.js" async />
                    <script src="/js/jq.js" async />
                    <script src="/js/jq.number.js" async />
                    <script src="/js/g2.js" async />
            </Head>

            <Component {...pageProps} />
         </> 
    );
}

export default appWithTranslation(MyApp);
