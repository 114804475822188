/*eslint-disable*/
import { getCookie, setCookie, deleteCookie } from 'cookies-next'
var url = ''
var device_id = ''
var defaultTime = 300 // 默认时间
var system_id = null // 系统id
var hadInit = false // 是否初始化
var authorization = ''

/**
 * @desc 初始化
 * @param {Number} sysid - 系统id
 * @param {String} env - 环境变量，可传'test'、'uat'、'stage'，不传默认取正式
 * @param {String} token - token必传
 * @param {Boolean} openPageView - 是否开启PV上报，默认开启
 * @param {Boolean} openClickBuried - 是否开启click上报，默认关闭
 * @param {Boolean} openScrollBuried - 是否开启scroll上报，默认关闭
 */
function init(
  sysid,
  env,
  token = null,
  openPageView = true,
  openClickBuried = false,
  openScrollBuried = false
) {
  hadInit = true
  system_id = sysid
  authorization = token
  switch (env) {
    case 'dev':
    case 'test':
      url = 'https://test-newapi.shoplus.net'
      break // 测试
    case 'uat':
      url = 'https://uat-newapi.shoplus.net'
      break // uat
    case 'stage':
      url = 'https://stage-newapi.shoplus.net'
      break // 预发布
    default:
      url = 'https://newapi.shoplus.net'
      break // 生产
  }
  const clickThrottle = throttle(buried, 3000)
  window.addEventListener('click', function () {
    refreshSessionId()
    if (openClickBuried) {
      clickThrottle({ event: 'commonClick' })
    }
  })

  const scrollThrottle = throttle(buried, 3000)
  window.addEventListener('scroll', function () {
    refreshSessionId()
    if (openScrollBuried) {
      scrollThrottle({ event: 'commonScroll' })
    }
  })

  // if (document.hidden !== undefined) {
  //   document.addEventListener("visibilitychange", () => {
  //     if(document.visibilityState === 'visible') { // 离开当前tab标签
  //       cookies.remove("BURIEDSDK_EXPIRES")
  //     }
  //   });
  // }

  refreshSessionId()
  if (getCookie('BURIEDSDK_DEVICEID')) {
    device_id = getCookie('BURIEDSDK_DEVICEID')
    if (openPageView) {
      buried({ event: 'pageView' })
    }
  } else {
    device_id = algorithm()
    console.log('device_id', device_id)
    setCookie('BURIEDSDK_DEVICEID', device_id, { path: '/', domain: '.shoplus.net' })
    buried({ event: 'firstVisit' })
  }
  // if (localStorage.getItem("BURIEDSDK_DEVICEID")) {
  //     device_id = localStorage.getItem("BURIEDSDK_DEVICEID");
  //     if (openPageView) {
  //         buried({ event: "pageView" });
  //     }
  // } else {
  //     device_id = algorithm();
  //     localStorage.setItem("BURIEDSDK_DEVICEID", device_id);
  //     buried({ event: "firstVisit" });
  // }
  if (openPageView) {
    initPageView()
  }
}

/**
 * @desc 数据上报
 * @param {Object} - { scene, page_name }参数对象
 * @param {String} event - 触发事件
 * @param {String} scene - 业务场景
 * @param {String} page_name - 页面名称
 * @param {String} env - 上报环境，可传test、uat、stage，不传默认正式环境
 */
function buried(
  { event, scene, page_name, channel_id, URL, token, ...other },
  multipleActions = []
) {
  // console.log("////", url);
  try {
    if (!hadInit) {
      console.log('need init first!')
      return
    }
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest()
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            //响应完成且成功
            const data = JSON.parse(xhr.responseText)
            resolve(data)
          } else {
            //响应完成但不成功
            reject(false)
          }
        }
      }
      xhr.open('POST', (URL || url) + '/api/v1/portal/statistics/common-user/action', true)

      xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
      xhr.setRequestHeader('authorization', token || authorization)
      refreshSessionId()
      var sUserAgent = navigator.userAgent
      var _isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
      // console.log('((((((((',cookies.get("LOCAL_UA"))

      // 转换json_str
      const getOther = (otherObj) => {
        let obj = { ...otherObj }
        if (obj && obj.json_str) {
          const json = JSON.parse(obj.json_str)
          obj.json_str = JSON.stringify({
            ...json,
            business_side: _isMobile ? 'H5' : 'PC',
            app_lang: getCookie('responsive-locale') || 'en'
          })
        } else {
          obj = obj || Object.create(null)
          obj.json_str = JSON.stringify({
            business_side: _isMobile ? 'H5' : 'PC',
            app_lang: getCookie('responsive-locale') || 'en'
          })
        }
        return obj
      }

      let defaultActions = {
        scene, // 业务场景
        page_name, // 页面名称
        event: event,
        page_url: window.location.href,
        language: navigator.language,
        device_id, // 设备id
        device_info: OnGetOS(), // 设备信息
        browser_info: getBrowser(),
        session_id: cookies.get('BURIEDSDK_EXPIRES') || refreshSessionId(), // 临时会话id
        net_type: getNetworkType(),
        referer: document.referrer,
        ...other
      }

      if (multipleActions && multipleActions?.length) {
        multipleActions = multipleActions.map((a) => {
          const item = { ...defaultActions, ...a }
          return { ...getOther(item) }
        })
      } else {
        defaultActions = getOther(defaultActions)
      }

      const actions =
        multipleActions && multipleActions?.length ? multipleActions : [defaultActions]
      var params = {
        actions,
        system_id
      }
      var query = getQueryParams()
      channel_id = channel_id || query.channel_id
      if (!isNaN(channel_id)) {
        params.channel_id = channel_id || query.channel_id
      }
      xhr.send(JSON.stringify(params))
    })
  } catch (error) {
    console.log(error)
  }
}

// 获取唯一数
function algorithm() {
  let abc = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'g',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z'
  ]
  let [max, min] = [
    Math.floor(Math.random() * (10 - 7 + 1) + 1),
    Math.floor(Math.random() * (17 - 10 + 1) + 17)
  ]
  abc = abc
    .sort(() => 0.4 - Math.random())
    .slice(max, min)
    .slice(0, 19)
    .join('')
  var a = Math.floor(new Date().getTime() * Math.random() * 100) + abc
  return a
}

// session_id刷新定义
function refreshSessionId() {
  const session_id = cookies.get('BURIEDSDK_EXPIRES') || algorithm()
  cookies.set('BURIEDSDK_EXPIRES', session_id)
  return session_id
}

// 判断设备
function OnGetOS() {
  var agent = navigator.userAgent.toLowerCase()
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  var MAC = /macintosh|mac os x/i.test(navigator.userAgent) && !window.MSStream
  var android = /Android/.test(navigator.userAgent) && !window.MSStream

  if (agent.indexOf('win32') >= 0 || agent.indexOf('wow32') >= 0) {
    return 'windows32 pc' // 此处根据需求调整
  }
  if (agent.indexOf('win64') >= 0 || agent.indexOf('wow64') >= 0) {
    return 'windows64 pc' // 此处根据需求调整
  }
  if (iOS) {
    return 'iphone'
  }
  if (MAC) {
    return 'mac'
  }
  if (android) {
    return 'andriod'
  }
  return 'unknow'
}

// 获取网络类型
function getNetworkType() {
  var ua = navigator.userAgent
  var networkStr = ua.match(/NetType\/\w+/) ? ua.match(/NetType\/\w+/)[0] : 'NetType/other'
  networkStr = networkStr.toLowerCase().replace('nettype/', '')
  var networkType
  switch (networkStr) {
    case 'wifi':
      networkType = 'wifi'
      break
    case '5g':
      networkType = '5g'
      break
    case '4g':
      networkType = '4g'
      break
    case '3g':
      networkType = '3g'
      break
    case '3gnet':
      networkType = '3g'
      break
    case '2g':
      networkType = '2g'
      break
    default:
      networkType = '网线连接'
  }
  return networkType
}
// 获取浏览器版本
function getBrowser() {
  const UserAgent = navigator.userAgent.toLowerCase()
  let browserInfo = ''
  const browserArray = {
    IE: window.ActiveXObject || 'ActiveXObject' in window, // IE
    Chrome: UserAgent.includes('chrome') && UserAgent.includes('safari'), // Chrome浏览器
    Firefox: UserAgent.includes('firefox'), // 火狐浏览器
    Opera: UserAgent.includes('opera'), // Opera浏览器
    Safari: UserAgent.includes('safari') && !UserAgent.includes('chrome'), // safari浏览器
    Edge: UserAgent.includes('edge'), // Edge浏览器
    QQBrowser: /qqbrowser/.test(UserAgent), // qq浏览器
    WeixinBrowser: /MicroMessenger/i.test(UserAgent) // 微信浏览器
  }
  for (let i in browserArray) {
    if (browserArray[i]) {
      let versions = ''
      if (i == 'IE') {
        versions =
          UserAgent.match(/(msie\s|trident.*rv:)([\w.]+)/) &&
          UserAgent.match(/(msie\s|trident.*rv:)([\w.]+)/)[2]
      } else if (i == 'Chrome') {
        for (const mt in navigator.mimeTypes) {
          // 检测是否是360浏览器(测试只有pc端的360才起作用)
          if (navigator.mimeTypes[mt].type == 'application/360softmgrplugin') {
            i = '360'
          }
        }
        versions = UserAgent.match(/chrome\/([\d.]+)/) && UserAgent.match(/chrome\/([\d.]+)/)[1]
      } else if (i == 'Firefox') {
        versions = UserAgent.match(/firefox\/([\d.]+)/) && UserAgent.match(/firefox\/([\d.]+)/)[1]
      } else if (i == 'Opera') {
        versions = UserAgent.match(/opera\/([\d.]+)/) && UserAgent.match(/opera\/([\d.]+)/)[1]
      } else if (i == 'Safari') {
        versions = UserAgent.match(/version\/([\d.]+)/) && UserAgent.match(/version\/([\d.]+)/)[1]
      } else if (i == 'Edge') {
        versions = UserAgent.match(/edge\/([\d.]+)/) && UserAgent.match(/edge\/([\d.]+)/)[1]
      } else if (i == 'QQBrowser') {
        versions =
          UserAgent.match(/qqbrowser\/([\d.]+)/) && UserAgent.match(/qqbrowser\/([\d.]+)/)[1]
      }
      browserInfo = i
      browserInfo += parseInt(versions)
    }
  }
  return browserInfo
}
// 页面路由监听
function initPageView() {
  class Dep {
    // 订阅池
    constructor(name) {
      this.subs = [] //该事件下被订阅对象的集合
    }
    defined(watch) {
      this.subs.push(watch)
    }
    notify() {
      //通知订阅者有变化
      this.subs.forEach((e, i) => {
        if (typeof e.update === 'function') {
          try {
            e.update.apply(e) //触发订阅者更新函数
          } catch (err) {
            console.error(err)
          }
        }
      })
    }
  }

  class Watch {
    constructor(name, fn) {
      this.name = name //订阅消息的名称
      this.callBack = fn //订阅消息发送改变时->订阅者执行的回调函数
    }
    add(dep) {
      //将订阅者放入dep订阅池
      dep.subs.push(this)
    }
    update() {
      //将订阅者更新方法
      var cb = this.callBack //赋值为了不改变函数内调用的this
      cb(this.name)
    }
  }

  function AddMethod() {
    let historyDep = new Dep()
    return function (name) {
      if (name === 'historychange') {
        return function (name, fn) {
          let event = new Watch(name, fn)
          historyDep.defined(event)
        }
      } else if (name === 'pushState') {
        let method = history[name]
        return function () {
          method.apply(history, arguments)
          historyDep.notify()
        }
      } else if (name === 'replaceState') {
        let method = history[name]
        return function () {
          method.apply(history, arguments)
          historyDep.notify()
        }
      }
    }
  }
  let addHistoryMethod = new AddMethod()
  window.addHistoryListener = addHistoryMethod('historychange')
  history.pushState = addHistoryMethod('pushState')

  window.addHistoryListener('history', function () {
    buried({ event: 'pageView' })
  })
}
// 获取url参数
function getQueryParams(url) {
  url = url || location.href
  const splitList = url.split('?')
  url = splitList[1] || ''
  const params = url
    .split('&')
    .filter(Boolean)
    .reduce((obj, item) => {
      const [key, value] = item.split('=')
      const val = decodeURIComponent(value)
      if (Array.isArray(obj[key])) {
        obj[key].push(val)
      } else if (typeof obj[key] === 'string') {
        obj[key] = [obj[key], val]
      } else {
        obj[key] = val
      }
      return obj
    }, {})
  return params
}
function throttle(fn, delay) {
  let previous = 0
  // 使用闭包返回一个函数并且用到闭包函数外面的变量previous
  return function () {
    const args = arguments
    const now = new Date()
    if (now - previous > delay) {
      fn.apply(this, args)
      previous = now
    }
  }
}

const cookies = {
  // 设置cookie
  set: (name, value, time = defaultTime) => {
    try {
      var result = JSON.stringify(value)
      if (/^[\{\[]/.test(result)) {
        value = result
      }
    } catch (e) {}
    var cookieString = name + '=' + value + '; path=/' //注意红色话语，这是保证不会出现多个key的，只修改对应路径的key
    //判断是否设置过期时间
    cookieString = cookieString + ';domain=.shoplus.net'
    if (time > 0) {
      var date = new Date(new Date() * 1 + time * 1000) // 秒
      cookieString = cookieString + ';expires=' + date.toUTCString()
    }
    document.cookie = cookieString
  },
  // 获取cookie
  get: (key) => {
    var arr = document.cookie.split('; ')
    for (var i = 0; i < arr.length; i++) {
      var arr1 = arr[i].split('=')
      if (arr1[0] == key) {
        return arr1[1]
      }
    }
    return undefined
  },
  // 删除cookie
  remove: (name) => {
    if (!name) return
    var exp = new Date()
    exp.setTime(exp.getTime() - 1)
    var cval = cookies.get(name)
    if (cval != null)
      document.cookie =
        name + '=' + cval + ';domain=.shoplus.net; path=/;expires=' + exp.toUTCString()
  }
}

if (typeof window !== 'undefined') {
  window.$buriedsdk = {
    init,
    buried
  }
}

export default {
  init,
  buried
}
